import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import { v4 as uuidv4 } from 'uuid';
import * as Constants from "../constants"
import { getServiceStatus, UP } from "../utils/ServiceAvailabilityCheck";

export function EligibilityCheck() {

    const { getAccessTokenSilently, getIdTokenClaims, isLoading } = useAuth0();
    const axios = require('axios');
    const navigate = useNavigate();

    const [isEligible, setIsEligible] = useState(null);
    const [eligibilityChecked, setEligibilityChecked] = useState(false);
    const [isSystemError, setIsSystemError] = useState(null);

    const [isServiceUp, setIsServiceUp] = useState(null);


    // Auth0 errors
    const [error, setError] = useState(null);
    const [errorDescription, setErrorDescription] = useState(null);

    // Check service availability
    useEffect(() => {
        const checkServiceStatus = async () => {
            const serviceStatus = await getServiceStatus();
            if (serviceStatus !== UP) {
                navigate("/service-unavailable?message=" + serviceStatus, { replace: true });
            } else {
                setIsServiceUp(true);
            }
        };
        checkServiceStatus();
    }, []);

    const checkEligibility = async () => {

        // Check for any error returned from Auth0, ie invalid ACR etc
        let params = new URLSearchParams(window.location.search);
        if (params.size >= 2 && params.get("error") != null && params.get("error_description") != null) {
            setError(params.get("error"));
            setErrorDescription(params.get("error_description"));
            setIsSystemError(true);
            return;
        }

        /*
            First, get access token. If this fails, user not logged in. Redirect
            to unauthorized page, IDP-5712
            
            User must be IP2CL2 otherwise display requirements not met.

            If user has a dla_id in their ID token they are already eligible
            otherwise perform the eligibility check.
        */

        try {
            const token = await getAccessTokenSilently();
            if (getIdTokenClaims && token) {

                getIdTokenClaims().then(claims => {
                    const auth0_userId = claims.sub;
                    const ipnValue = claims["http://oauth.tmr.qld.gov.au"].ipn;
                    const mfaAvailable = claims["http://oauth.tmr.qld.gov.au"].mfa_available;

                    if (Constants.DEBUG) {
                        console.log("User %s and has achieved %s, mfa_available is %s", auth0_userId, ipnValue, mfaAvailable);
                    }

                    if (!mfaAvailable || (!ipnValue || ipnValue !== "ip2")) {
                        // User does not have IP2 and/or not enrolled in MFA
                        if (Constants.DEBUG) { console.log("User must be at least IP2:CL2"); }
                        setIsEligible(false);
                        return;

                    } else {
                        const dla_id = claims["http://oauth.tmr.qld.gov.au"].dla_id;
                        if (dla_id) {
                            if (Constants.DEBUG) { console.log("User already has dla_id and is eligible"); }
                            setIsEligible(true);
                            return;
                        }
                    }

                    /* CHECK ELIGIBILITY */

                    if (Constants.DEBUG) { console.log("Checking eligibility of user..."); }

                    // Auth0 passes us a correlation_id it recieved from the broker, otherwise we will assign one
                    let correlation_id = claims["http://oauth.tmr.qld.gov.au"].correlation_id;
                    if (correlation_id) {
                        // Using Auth0 provided correlation ID
                        if (Constants.DEBUG) console.log('Using Auth0 provided correlation _id >%s', correlation_id)
                    } else {
                        // Assigning default correlation ID
                        correlation_id = uuidv4();
                        if (Constants.DEBUG) console.log('No correlation_id found in Auth0 session token. Assigning default >%s', correlation_id);
                    }

                    var HttpCodes = { OK: 200, notFound: 404 }
                    const payload = { DLAUserAccount: { user_id: auth0_userId } };
                    const headers = {
                        'Authorization': 'Bearer ' + token,
                        'eip_client_id': `${process.env.react_app_eip_client_id}`,
                        'X-Correlation-ID': correlation_id
                    };
                    const x_api_url = process.env.react_app_audience;

                    axios
                        .post(x_api_url + "qdi/users/dla/eligibilitycheck", payload, { headers })
                        .then((response) => {
                            if (Constants.DEBUG) { console.log(response); }
                            if (response.status === HttpCodes.OK) {
                                if (Constants.DEBUG) { console.log('User %s is eligible', auth0_userId); }
                                setIsEligible(true);
                            }
                        })
                        .catch((error) => {
                            const httpStatus = error.response.status;
                            const message = error.response.data.Results[0]?.ErrorResponse.Description;

                            if (httpStatus === HttpCodes.notFound) {
                                // 404, either 'Not eligible', 'TMR document required' or 'Third document required'
                                if (Constants.DEBUG) { console.log("User %s is not eligible, reason %s", auth0_userId, message); }
                                setIsEligible(false);

                            } else {
                                /* Non 404 errors such as 500, system errors etc */
                                if (Constants.DEBUG) { console.log("System error, %s", error); }
                                setIsSystemError(true);
                            }
                        });

                    setEligibilityChecked(true);

                }).catch(err => {
                    if (Constants.DEBUG) { console.log("Error retrieving users claims", err); }
                    setIsSystemError(true);
                })

            } else {
                if (Constants.DEBUG) { console.log("Error retrieving users access token and/or claims"); }
                setIsSystemError(true);
            }

        } catch (error) {
            if (Constants.DEBUG) { console.log("User not authenticated. Redirecting to /login..."); }
            navigate("/Unauthorized", { replace: true });
        }
    }

    /**
     * After page load, check eligibility, IDP-4978
     */
    useEffect(() => {
        if (isServiceUp && !isLoading && !eligibilityChecked) {
            checkEligibility();
        }
    }, [isLoading, isServiceUp]);

    /**
     * If eligible, call deep link. If still here after timeout display system error.
     */
    useEffect(() => {
        if(isEligible !== null) {

            if (isEligible) {
                if (Constants.DEBUG) {
                    console.log("User has passed eligibility. Callling deep link to launch mobile app...");
                }               
                window.location.href = process.env.react_app_deep_link;
                setTimeout(function () {
                    // If we're still here after timeout, display system error
                    console.log("Failed to launch mobile app!");
                    navigate("/SysError", { replace: true });
                }, 5500);
    
            } else {
                navigate("/Ineligible", { replace: true });
            }
        }
    }, [isEligible, navigate]);

    /**
    * Triggered when system error flag set true. Redirect to system error page.
    */
    useEffect(() => {
        if (isSystemError) {
            if (Constants.DEBUG && (error && errorDescription)) {
                // Auth0 error occurred. Log error
                console.log("Auth0 error, %s, %s", error, errorDescription);
            }
            navigate("/SysError", { replace: true });
        }
    }, [isSystemError, navigate]);

    return (
        <>
            <h1>Queensland Digital Licence App</h1>
            <Loading />
        </>
    );

};

export default EligibilityCheck;