import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from "../constants"
import { useNavigate } from "react-router-dom";
//import { Link } from "react-router-dom";

/**
 * Displayed when not eligible due to cancelling from entering TMR product after
 * directed to do so because they came to DLA as IP2CL2 but had not TMR product
 * 
 * @returns the page
 */
export default function Ineligible() {

    const { isAuthenticated, logout } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        if(isAuthenticated) {
            if(Constants.DEBUG) { console.log("Not eligible. Logging user out from Auth0..."); }
            logout({ localOnly: true, });
            localStorage.clear();
            sessionStorage.clear();  
        } else {
            if (Constants.DEBUG) { console.log("User not authenticated. Redirecting to unauthorized page..."); }
            navigate("/Unauthorized", { replace: true });
        }
    }, []);

    return (

        <div style={{ margin: "0 auto" }} >

            <h1>You have not met the eligibility requirements for the Digital Licence app</h1>

            <div className="alert alert-danger" role="alert">

                <h2><i className="fa fa-times-circle" style={{ float: "left" }}></i></h2>
                Select 'Cancel' or 'X' to close this browser - you will be returned to the opening
                screen of the Digital Licence app. Visit the <a href={Constants.DIGITAL_LICENCE_APP_WEBSITE} target="_blank" rel="noopener">Digital Licence app website</a>
                for more information.

            </div>

        </div>
    );
}